import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { CatalystLogo } from 'src/components/common'
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes'
import { getDeviceType, isAndroid } from 'src/utils/inspectDeviceType';
import {BotTypePreference, PostMessagesTypes, Tabs} from 'src/constans/constans';
import { setVoiceChatInfo } from 'src/api/voiceChatApi';
import { useGetChatId } from 'src/hooks/chatId';
import { useClickOutside } from 'src/hooks/useClickOutside'
import PersistentMenu from 'src/components/PersistentMenu/PersistentMenu';
import { BOT_ID } from 'src/config'
import {
  CHANGE_NOTIFICATIONS, PLAY,
  SIGN_OUT,
  SPEAKER,
  SPEAKER_OFF
} from 'src/constans/svgIcons'
import { theme } from 'src/themes/Theme';

import {
  ButtonTitle,
  ControlButton,
  ControlButtonTooltip,
  ControlPanelContainer,
  HeaderContainer,
  LogoContainer
} from './Header.styled'

interface Props {
  activeTab: string,
  botTypePreference: string
  setBotTypePreference: Dispatch<SetStateAction<BotTypePreference>>
  widgetSource: MessageEventSource
  setIsShowLogOutModal: (p: boolean) => void
  setIsShowNotificationModal: (p: boolean) => void
  isMenuOpen: boolean,
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  logOut: () => void
  updatingAudioQueue: (data: string) => void
  stopPlayAndResetAudioQueue: () => void
  isShowTooltip: boolean
  setIsShowTooltip: Dispatch<SetStateAction<boolean>>
}

const Header: FC<Props> = ({
  activeTab,
  botTypePreference,
  setBotTypePreference,
  widgetSource,
  setIsShowLogOutModal,
  setIsShowNotificationModal,
  isMenuOpen,
  setIsMenuOpen,
  logOut,
  updatingAudioQueue,
  stopPlayAndResetAudioQueue,
  isShowTooltip,
  setIsShowTooltip
}) => {
  const IsActiveRepeatVoice = activeTab === Tabs.TAB_1 && botTypePreference === BotTypePreference.VOICE;
  const isMobile = getDeviceType() === 'mobile';
  const { currentChatId } = useGetChatId();
  const repeatActive = isMobile && !isAndroid ? JSON.parse(localStorage.getItem('widget-' + BOT_ID))?.repeatActive : false;
  const [timerId, setTimerId] = useState(null)
  const tooltipRef = useClickOutside(() => {
    setIsShowTooltip(false)
    clearTimeout(timerId)
  })

  const voiceChatHandler = () => {
    const voiceStatus = botTypePreference === BotTypePreference.VOICE ? BotTypePreference.TEXT : BotTypePreference.VOICE
    setVoiceChatInfo(currentChatId, voiceStatus).then(res => {
      res.bot_type_preference === BotTypePreference.VOICE && setIsShowTooltip(true)
      setBotTypePreference(res.bot_type_preference)

      stopPlayAndResetAudioQueue()
      widgetSource.postMessage({
        type: PostMessagesTypes.WIDGET_CANCEL_VOICE_SPEAKING
      }, '*' as WindowPostMessageOptions)
    })
  }


  const audioQueueHandling = () => {
    widgetSource.postMessage({
      type: 'widget-say-test'
    }, '*' as WindowPostMessageOptions)
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (isShowTooltip) {
      timeoutId = setTimeout(() => {
        setIsShowTooltip(false)
      }, 5000)

      setTimerId(timeoutId)
    }

    return () => clearTimeout(timeoutId)
  }, [isShowTooltip])

  const logOutHandler = () => setIsShowLogOutModal(true)
  const notificationHandler = () => setIsShowNotificationModal(true)
  const voiceChat = useMemo(() => {
    let title: string
    let icon: string
    switch (botTypePreference) {
      case BotTypePreference.VOICE:
        title = 'Voice chat is on'
        icon = SPEAKER
        break
      case BotTypePreference.TEXT:
        title = 'Voice chat is off'
        icon = SPEAKER_OFF
        break
      default:
        title = 'Voice chat is on'
        icon = SPEAKER
    }

    return { title, icon }
  }, [botTypePreference])

  return (
    <HeaderContainer isMobile={isMobile}>
      <PersistentMenu
        isMobile={isMobile}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        widgetSource={widgetSource}
        logOut={logOut}
        updatingAudioQueue={updatingAudioQueue}
        stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
      />
      <LogoContainer isMobile={isMobile}>
        <CatalystLogo />
      </LogoContainer>

      <ControlPanelContainer>
        {isShowTooltip && <ControlButtonTooltip
          ref={tooltipRef}
          isMobile={isMobile}
          isAndroid={isAndroid}
        >
          Voice chat is on and can be turned off by clicking the icon.
        </ControlButtonTooltip>}
        <ControlButton
          isMobile={isMobile}
          onClick={voiceChatHandler}
        >
          {!isMobile && <ButtonTitle>{ voiceChat.title }</ButtonTitle>}
          <SvgTypes
            type={voiceChat.icon}
            color={theme.colors.additionalText}
          />
        </ControlButton>

        {isMobile && !isAndroid && <ControlButton
          style={{
            cursor: IsActiveRepeatVoice && repeatActive ? 'pointer' : 'not-allowed',
            opacity: IsActiveRepeatVoice && repeatActive ? 1 : 0.2,
          }}
          disabled={!IsActiveRepeatVoice || !repeatActive}
          isMobile={isMobile}
          onClick={audioQueueHandling}>
          <SvgTypes
            type={PLAY}
            color={theme.colors.additionalText}
          />
        </ControlButton>}

        <ControlButton
          isMobile={isMobile}
          onClick={notificationHandler}
        >
          {!isMobile && <ButtonTitle>Notification preferences</ButtonTitle>}
          <SvgTypes
            type={CHANGE_NOTIFICATIONS}
            color={theme.colors.additionalText}
          />
        </ControlButton>
        <ControlButton
          isMobile={isMobile}
          onClick={logOutHandler}
        >
          {!isMobile && <ButtonTitle>Log out</ButtonTitle>}
          <SvgTypes
            type={SIGN_OUT}
            color={theme.colors.additionalText}
          />
        </ControlButton>
      </ControlPanelContainer>
    </HeaderContainer>
  )
}

export default Header
