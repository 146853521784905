import styled from 'styled-components';

export const IntroPageStyled = styled.div`
  color: ${props => props.theme.colors.additionalText};
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  
  & button {
    width: ${props => (props.isMobile ? '100%' : '265px')};
    height: 48px;
    min-height: 48px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  .submitButton {
    background-color: ${props => props.theme.colors.secondaryButtonDefaultColor};
    color: ${props => props.theme.colors.white};
    border: ${props => (props.isMobile ? '1px solid' : 'none')};
    box-shadow: 0px -4px 16px rgba(149, 169, 200, 0.15);
    margin-bottom: 12px;
    
    &:last-child {
      margin-bottom: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colors.secondaryButtonHoverColor};
      }
    }

    &:active {
      background-color: ${props => props.theme.colors.secondaryButtonFocusColor};
    }
    
    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
    }
  }
  
  .ghostButton {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.secondary};
    
    &:hover {
      background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
      border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
      color: ${props => props.theme.colors.singleSelectButtonTextColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.userInputBorder};
      border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
      color: ${props => props.theme.colors.ghostButtonActiveTextColor};
    }
  }
`

export const IntroPageContentStyled = styled.p`
  color: ${props => props.theme.colors.additionalBlue};
  width: 100%;
  max-width: 365px;
  height: 100%;
  margin: ${props => (props.isMobile ? '0 0 16px' : '0 0 24px')};
  flex-grow: 1;
  display: flex;
  
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollColor};
    border-radius: 2px;
  }
`
