import styled from 'styled-components';

export const PersistentMenuContainer = styled.div`
  position: relative;
`

export const PersistentMenuButton = styled.button`
  border: none;
  background-color: ${props => props.theme.colors.mainButtonFocusColor};
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  margin: ${props => (props.isMobile ? '0 14px 0 0' : '0 40px 0 0')};
  color: ${props => props.theme.colors.white};
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colors.mainButtonHoverColor};
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.mainButtonFocusColor};
  }
`

export const PersistentMenuPopUp = styled.div`
  border-radius: 16px;
  background: ${props => props.theme.colors.white};
  box-shadow: 8px 4px 16px 0 rgba(61, 64, 91, 0.25);
  position: absolute;
  top: 48px;
  left: 0;
  width: 288px;
  max-height: 530px;
  z-index: 9;
  box-sizing: border-box;
`

export const PersistentMenuList = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
`

export const PersistentMenuItem = styled.li`
  list-style-type: none;
  border-bottom: 1px solid ${props => (props.enbled ? props.theme.colors.userInputBorder : props.theme.colors.disabledMenuBorder)};
  background-color: ${props => (props.enbled ? props.theme.colors.primary : props.theme.colors.disabledMenuItem)};
  
  &:active {
    background-color: ${props => (props.enbled && props.theme.colors.activeMenuItem)};
  }
  
  &:first-child {
    border-radius: 16px 16px 0 0;
  }
  
  &:last-child {
    border: none;
    border-radius: 0 0 16px 16px;
  }
`

export const PersistentCustomMenuItem = styled(PersistentMenuItem)`
  padding: 10px 12px;
`

export const PersistentMenuItemButton = styled.button`
  background-color: ${props => props.theme.colors.transparent};
  color: ${props => (props.enbled ? props.theme.colors.white : props.theme.colors.disabledMenuItemFont)};
  font-family: ${props => props.theme.fonts};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
  padding: 10px 12px;
  cursor: ${props => (props.enbled ? 'pointer' : 'auto')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
`

export const PersistentMenuAskButton = styled.button`
  width: 100%;
  height: 44px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 16px;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
    border-color: ${props => props.theme.colors.singleSelectButtonTextColor};
    color: ${props => props.theme.colors.singleSelectButtonTextColor};
  }

  &:active {
    background-color: ${props => props.theme.colors.userInputBorder};
    border-color: ${props => props.theme.colors.ghostButtonActiveBorderColor};
    color: ${props => props.theme.colors.ghostButtonActiveTextColor};
  }
`
