import React from 'react';

import { LogoStyled } from './Logo.styled';
import { CatalystLogo } from './CatalystLogo';

export const Logo = ({ isMobile }) => {
  return (
    <LogoStyled isMobile={isMobile}>
      <CatalystLogo className='catalystLogo'/>
    </LogoStyled>
  )
}
