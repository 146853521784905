import React, { useEffect } from 'react';
import { SingleSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/SingleSelectPage';
import { MultiSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/MultiSelectPage';
import { UserInputPage } from 'src/pages/RiskAssessmentQuestionnairePage/UserInputPage';
import { DropdownSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/DropdownSelectPage';
import {
  useHandleGetQuestionByQuestionnaireStatus,
  useSaveReplyRequestHookQuery
} from 'src/hooks/api/useRiskAssessmentQuestionaire';
import {
  RiskAssessmentQuestionnairePage
} from 'src/pages/RiskAssessmentQuestionnairePage/RiskAssessmentQuestionnairePage';
import { QuestionTypes } from 'src/constans/constans';

const QuestionType = {
  [QuestionTypes.SINGLE_SELECT]: SingleSelectPage,
  [QuestionTypes.MULTI_SELECT]: MultiSelectPage,
  [QuestionTypes.USER_INPUT]: UserInputPage,
  [QuestionTypes.DROPDOWN_SELECT]: DropdownSelectPage,
  [QuestionTypes.DEFAULT]: SingleSelectPage,
}

export const RiskAssessmentQuestionnaireHandler = ({
  currentPage,
  currentQuestionnaireStatus,
  botTypePreference,
  familyTreeSettings,
  setFamilyTreeSettings,
  updatingAudioQueue,
  stopPlayAndResetAudioQueue,
  stopSpeakingUtterance,
  isWidgetActiveTab,
  isWidgetFinishedPlay,
  isWidgetStartedPlay
}) => {
  const chatId = localStorage.getItem('chatId');
  const { data, isSuccess, isFetching } = useHandleGetQuestionByQuestionnaireStatus(chatId, currentPage, currentQuestionnaireStatus);
  const {mutate: saveReplyRequest} = useSaveReplyRequestHookQuery(currentPage);
  const currentQuestionType = data?.question_type || QuestionTypes.DEFAULT;
  const CurrentType = QuestionType[currentQuestionType];

  const soundProcessor = (sounds) => {
    if (sounds.length && !isWidgetActiveTab) {
      sounds.forEach((sound) => {
        updatingAudioQueue(sound)
      })
    }
  }

  useEffect(() => {
    if (!isWidgetStartedPlay || isWidgetFinishedPlay) {
      (data?.voice && !isFetching) && soundProcessor(data.voice)
    }
  }, [data, isWidgetFinishedPlay, isFetching]);

  return (isSuccess ?
    <>
      <RiskAssessmentQuestionnairePage
        questionnaire_progress={data?.questionnaire_progress}
        custom_tags={data?.custom_tags}
        updatingAudioQueue={updatingAudioQueue}
        stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
        stopSpeakingUtterance={stopSpeakingUtterance}
      >
        <CurrentType
          data={data}
          onNext={saveReplyRequest}
          chatId={chatId}
          speechSynthesis={speechSynthesis}
          botTypePreference={botTypePreference}
          familyTreeSettings={familyTreeSettings}
          setFamilyTreeSettings={setFamilyTreeSettings}
          stopSpeakingUtterance={stopSpeakingUtterance}
          stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
        />
      </RiskAssessmentQuestionnairePage>
    </>
    : null
  )
}
