import styled, { css } from 'styled-components';

export const LogoStyled = styled.div`
  width: 100%;

  .catalystLogo {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    
    & img {
      ${props => props.isMobile && css`
        height: 52px;
      `}
    }
  }
`;
